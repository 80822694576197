<template>
  <div v-if="profile_loaded" class="profile" id="my-profile">
    <h3>Configuración</h3>
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell--span-8">
        <h4>Datos personales</h4>
        <!--<div class="pp-container">
          <i class="material-icons pp-icon">supervised_user_circle</i>
          <div class="pp-subcontainer">
            <span>Tu nombre</span>
            <span class="pp-dialog-text">Cambiar foto del perfil</span>
          </div>
        </div>-->
        <div class="helper-input-parent">
          <span class="helper-input">Nick</span>
          <div class="profile-input-disabled-parent">
            <input placeholder="No hay información" v-model="user_data_values.input_nick"
              class="profile-input profile-text-disabled" type="text" disabled>
          </div>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Nombre y apellidos</span>
          <div class="profile-input-disabled-parent">
            <input placeholder="No hay información" v-model="user_data_values.input_name"
              class="profile-input profile-text-disabled" type="text" disabled>
          </div>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Correo electrónico</span>
          <div class="profile-input-disabled-parent">
            <input placeholder="No hay información" v-model="user_data_values.input_email"
              class="profile-input profile-text-disabled" type="text" disabled>
          </div>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Teléfono</span>
          <div class="profile-input-disabled-parent">
            <input placeholder="No hay información" v-model="user_data_values.input_num_phone"
              class="profile-input profile-text-disabled" type="text" disabled>
          </div>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Género</span>
          <div class="profile-input-disabled-parent">
            <select v-model="user_data_values.genre_select_class" class="profile-input profile-text-disabled" disabled>
              <option disabled selected value="">Elige un valor</option>
              <option v-for="gender in Object.keys(select_options.gender_options)" :value="gender" :key="'G' + gender">{{
                select_options.gender_options[gender] }}</option>
            </select>
          </div>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Fecha de nacimiento (AAAA-MM-DD)</span>
          <input id="dateinput" placeholder="No hay información" v-model="user_data_values.input_date"
            class="profile-input" type="text" @input="formatDate" @keydown="restrictInput">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Categoría profesional</span>
          <input placeholder="No hay información" v-model="user_data_values.input_category" class="profile-input"
            type="text">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Trabajo</span>
          <input placeholder="No hay información" v-model="user_data_values.input_job" class="profile-input" type="text">
        </div>

        <h4>¿Cómo nos has conocido?</h4>
        <select v-model="user_data_values.know_us" class="profile-input">
          <option disabled selected value="">Elige un valor</option>
          <option v-for="how in Object.keys(select_options.howknow_options)" :value="how" :key="'C' + how">{{
            select_options.howknow_options[how] }}</option>
        </select>
      </div>

      <div class="mdc-layout-grid__cell--span-8">
        <h4>Dirección</h4>
        <div class="helper-input-parent">
          <span class="helper-input">País</span>
          <select v-model="user_data_values.countries" class="profile-input">
            <option disabled selected value="">Elige un valor</option>
            <option v-for="country in Object.keys(select_options.country_options)" :value="country" :key="'C' + country">{{
              select_options.country_options[country] }}</option>
          </select>
        </div>
        <div v-if="user_data_values.countries == 68" class="helper-input-parent">
          <span class="helper-input">Provincia</span>
          <select v-model="user_data_values.states" class="profile-input">
            <option disabled selected value="">Elige un valor</option>
            <option v-for="city in Object.keys(select_options.city_options)" :value="city" :key="'C' + city">{{
              select_options.city_options[city] }}</option>
          </select>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Localidad</span>
          <input placeholder="No hay información" v-model="user_data_values.input_town" class="profile-input" type="text">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Dirección</span>
          <input placeholder="No hay información" v-model="user_data_values.input_address" class="profile-input"
            type="text">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Código postal</span>
          <input placeholder="No hay información" v-model="user_data_values.input_postcode" class="profile-input"
            type="text">
        </div>

        <h4>Dirección de facturación</h4>
        <div class="helper-input-parent">
          <span class="helper-input">País</span>
          <select v-model="user_data_values.countries_bill" class="profile-input">
            <option disabled selected value="">Elige un valor</option>
            <option v-for="country in Object.keys(select_options.country_options)" :value="country" :key="'C' + country">{{
              select_options.country_options[country] }}</option>
          </select>
        </div>
        <div v-if="user_data_values.countries_bill == 68" class="helper-input-parent">
          <span class="helper-input">Provincia</span>
          <select v-model="user_data_values.states_bill" class="profile-input">
            <option disabled selected value="">Elige un valor</option>
            <option v-for="city in Object.keys(select_options.city_options)" :value="city" :key="'C' + city">{{
              select_options.city_options[city] }}</option>
          </select>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Localidad</span>
          <input placeholder="No hay información" v-model="user_data_values.input_town_bill" class="profile-input"
            type="text">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Dirección</span>
          <input placeholder="No hay información" v-model="user_data_values.input_address_bill" class="profile-input"
            type="text">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Código postal</span>
          <input placeholder="No hay información" v-model="user_data_values.input_postcode_bill" class="profile-input"
            type="text">
        </div>
      </div>

      <div class="mdc-layout-grid__cell--span-8">
        <h4>Datos fiscales</h4>
        <div class="helper-input-parent">
          <span class="helper-input">NIF</span>
          <div class="profile-input-disabled-parent">
            <input placeholder="No hay información" v-model="user_data_values.input_num_fiscal"
              class="profile-input profile-text-disabled" type="text" disabled>
          </div>
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Nombre y apellidos</span>
          <div class="profile-input-disabled-parent">
            <input placeholder="No hay información" v-model="user_data_values.input_name"
              class="profile-input profile-text-disabled" type="text" disabled>
          </div>
        </div>

        <h4>Preferencias</h4>
        <div class="checkboxes-container">
          <div class="checkbox-bottom-helper-parent">
            <div class="helper-checkbox-parent LOPD-parent">
              <input v-model="user_data_values.key_lopd" type="checkbox">
              <span>Acepto LOPD</span>
            </div>
            <span class="terminos">Marcando la casilla aceptas los <a href="/terminos-LOPD">términos y
                condiciones</a></span>
          </div>
          <div class="checkbox-group-parent">
            <div class="helper-checkbox-parent">
              <input v-model="user_data_values.key_newletter" type="checkbox">
              <span>Acepto Newsletter</span>
            </div>
            <div class="helper-checkbox-parent">
              <input v-model="user_data_values.key_email" type="checkbox">
              <span>Acepto correos</span>
            </div>
            <div class="helper-checkbox-parent">
              <input v-model="user_data_values.key_sms" type="checkbox">
              <span>Acepto SMS</span>
            </div>
          </div>
          <div class="helper-checkbox-parent">
            <input v-model="user_data_values.key_img_video" type="checkbox">
            <span>Gestión de imágenes/vídeo</span>
          </div>
          <div @click="discardChanges" class="profile-button">DESCARTAR CAMBIOS</div>
          <div @click="saveProfileInfo" class="profile-button">GUARDAR CAMBIOS</div>
        </div>

        <h4>Contraseña</h4>
        <div @click="goToChangePassword" class="profile-button">CAMBIAR CONTRASEÑA</div>

        <!--
        <h4>Opciones de contraseña</h4>
        <div class="profile-section-header"><span>Opciones de contraseña</span></div>
        <div class="helper-input-parent">
          <span class="helper-input">Contraseña actual</span>
          <input placeholder="No hay información" class="profile-input" type="text">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Contraseña nueva</span>
          <input placeholder="No hay información" class="profile-input" type="text">
        </div>
        <div class="helper-input-parent">
          <span class="helper-input">Repite la contraseña</span>
          <input placeholder="No hay información" class="profile-input" type="text">
        </div>
        <div class="profile-button">GUARDAR CONTRASEÑA</div>
        -->
      </div>
    </div>
  </div>
  <div id="profile-fail-load" v-else>
    <h1>Hay problemas recuperando el perfil.<br>Por favor, inténtelo de nuevo más tarde o póngase en contacto con nosotros.</h1>
    <span @click="discardChanges">Intentar de nuevo</span>
    <span @click="goToStockchart">Volver al Graficador Dinámico</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins: [APICaller],
  name: 'MyProfile',
  components: {

  },
  data() {
    return {
      user_data_values: {
        input_nick: '',
        input_name: '',
        input_email: '',
        input_num_phone: '',
        genre_select_class: '',
        input_category: '',
        input_job: '',
        countries: '',
        states: '',
        input_town: '',
        input_address: '',
        input_postcode: '',
        know_us: '',
        input_num_fiscal: '',
        input_name_fiscal: '',
        countries_bill: '',
        states_bill: '',
        input_town_bill: '',
        input_address_bill: '',
        input_postcode_bill: '',
        input_date: '',
        key_lopd: true,
        key_newletter: false,
        key_email: false,
        key_sms: false,
        key_img_video: false,
      },
      select_options: {
        gender_options: {},
        country_options: {},
        city_options: {},
        howknow_options: {}
      },
      profile_loaded: true
    };
  },
  computed: {
    ...mapGetters(["_g_UserId"])
  },
  created() {
    this.loadProfileInfo();
  },
  mounted() {

  },
  methods: {
    loadProfileInfo: function () {
      let success = (response) => {
        if(response.data.profile_info) {
          this.select_options.gender_options = response.data.genero_options;
          this.select_options.country_options = response.data.pais_options;
          this.select_options.city_options = response.data.provincia_options;
          this.select_options.howknow_options = response.data.conocido_options;

          this.user_data_values.input_nick = response.data.profile_info.nick;
          this.user_data_values.input_name = response.data.profile_info.name;
          this.user_data_values.input_email = response.data.profile_info.email;
          this.user_data_values.input_num_phone = response.data.profile_info.mobile;
          this.user_data_values.genre_select_class = response.data.profile_info.sexo;
          this.user_data_values.input_category = response.data.profile_info.categoria;
          this.user_data_values.input_job = response.data.profile_info.profesion;
          this.user_data_values.countries = response.data.profile_info.country_id[0] && response.data.profile_info.country_id[0] !== "" ? response.data.profile_info.country_id[0] : 68;
          this.user_data_values.states = response.data.profile_info.state_id[0] && response.data.profile_info.state_id[0] !== "" ? response.data.profile_info.state_id[0] : 417;
          this.user_data_values.input_town = response.data.profile_info.city;
          this.user_data_values.input_address = response.data.profile_info.street;
          this.user_data_values.input_postcode = response.data.profile_info.zip;
          this.user_data_values.know_us = response.data.profile_info.how_know;
          this.user_data_values.input_num_fiscal = response.data.profile_info.cif;
          this.user_data_values.input_name_fiscal = response.data.profile_info.fiscal_name;
          this.user_data_values.countries_bill = response.data.profile_info.fiscal_country_id[0] && response.data.profile_info.fiscal_country_id[0] !== "" ? response.data.profile_info.fiscal_country_id[0] : 68;
          this.user_data_values.states_bill = response.data.profile_info.fiscal_state_id[0] && response.data.profile_info.fiscal_state_id[0] !== "" ? response.data.profile_info.fiscal_state_id[0] : 417;
          this.user_data_values.input_town_bill = response.data.profile_info.fiscal_city;
          this.user_data_values.input_address_bill = response.data.profile_info.fiscal_street;
          this.user_data_values.input_postcode_bill = response.data.profile_info.fiscal_postal_code;
          this.user_data_values.input_date = response.data.profile_info.fecha_nacimiento;
          this.user_data_values.key_lopd = response.data.profile_info.si_lopd;
          this.user_data_values.key_newletter = response.data.profile_info.si_newsletters;
          this.user_data_values.key_email = response.data.profile_info.si_messages;
          this.user_data_values.key_sms = response.data.profile_info.si_sms_messages;
          this.user_data_values.key_img_video = response.data.profile_info.si_images;
        }
        else {
          this.profile_loaded = false;
        }
      };

      let failure = () => {
        this.profile_loaded = false;
      }

      let url = '/api/v1/profile-info'

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('350', 'Error recuperando información de usuario.', failure);

      this._getAPICall(url, successHandler, failureHandler);
    },

    saveProfileInfo: function () {
      let url = '/api/v1/write-profile-info';
      const successHandler = new APICaller.SuccessHandler(this.discardChanges);
      const failureHandler = new APICaller.FailureHandler('351', 'Error escribiendo la información de usuario');
      const params = new URLSearchParams;
      params.append("input_nick", this.user_data_values.input_nick);
      params.append("input_name", this.user_data_values.input_name);
      params.append("input_email", this.user_data_values.input_email);
      params.append("input_num_phone", this.user_data_values.input_num_phone);
      params.append("genre_select_class", this.user_data_values.genre_select_class);
      params.append("input_category", this.user_data_values.input_category);
      params.append("input_job", this.user_data_values.input_job);
      params.append("countries", this.user_data_values.countries);
      params.append("states", this.user_data_values.states);
      params.append("input_town", this.user_data_values.input_town);
      params.append("input_address", this.user_data_values.input_address);
      params.append("input_postcode", this.user_data_values.input_postcode);
      params.append("know_us", this.user_data_values.know_us);
      params.append("input_num_fiscal", this.user_data_values.input_num_fiscal);
      params.append("input_name_fiscal", this.user_data_values.input_name_fiscal);
      params.append("countries_bill", this.user_data_values.countries_bill);
      params.append("states_bill", this.user_data_values.states_bill);
      params.append("input_town_bill", this.user_data_values.input_town_bill);
      params.append("input_address_bill", this.user_data_values.input_address_bill);
      params.append("input_postcode_bill", this.user_data_values.input_postcode_bill);
      params.append("input_date", this.user_data_values.input_date);
      params.append("key_lopd", this.user_data_values.key_lopd);
      params.append("key_newletter", this.user_data_values.key_newletter);
      params.append("key_email", this.user_data_values.key_email);
      params.append("key_sms", this.user_data_values.key_sms);
      params.append("key_img_video", this.user_data_values.key_img_video);

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    discardChanges: function () {
      location.reload();
    },

    restrictInput(event) {
      // Get the current input value and key code of the pressed key
      const inputValue = event.target.value;
      const keyCode = event.keyCode || event.which;

      // Allow only digits and dashes
      if (keyCode !== 45 && (keyCode < 48 || keyCode > 57) && keyCode !== 8) {
        event.preventDefault();
      }

      // Restrict the input to the format of YYYY-MM-DD
      if ((inputValue.length === 4 || inputValue.length === 7) && keyCode !== 8) {
        event.target.value += '-';
      }
    },
    formatDate() {
      // Get the current input value
      const inputValue = this.user_data_values.input_date;

      // Restrict the input to the format of YYYY-MM-DD
      if (inputValue.length > 10) {
        this.user_data_values.input_date = inputValue.substring(0, 10);
      }
    },
    goToChangePassword() {
      this.$router.push("change-password");
    },
    goToStockchart() {
      this.$router.push('graficador-dinamico');
    }
  }
}
</script>




<style lang="scss" scoped>
h3 {
  margin: 0;
  padding: 30px 20px 0;
}

h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--color-text-secondary);
  background-color: var(--color-surface);
  padding: 20px 26px;
  margin-top: 30px;
  width: 75%;
}

.profile-box-icon {
  display: flex;
  align-items: center;
  height: 34px;
  margin: 20px 0;
}

.input-container-size {
  width: 100%;
}

.align-profile-img {
  display: flex;
  align-items: center;
  margin: 29px 0 35px;
}

.text-profile {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000010;
}

.text-prifle-blue {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #0080B6;
  text-decoration: none;
}

i {
  font-size: 16px;
  color: #00add8;
}

.icon-profile {
  margin-right: 13px;
  font-size: 50px;
  color: #000010;
}

.checkboxes-container {
  display: flex;
  flex-direction: column;
}

.checkboxes-container span {
  color: var(--color-text-primary);
}

.mdc-select--outlined {
  margin: 29px 0 47px;
}

button {
  margin: 5px;
}

.input-container-size {
  margin: 20px 0;
}

.select-container-size {
  margin: 20px 0;
}

.profile-margin-checkbox {
  margin-left: 15px;
}

.profile-text-small {
  margin: 0 8px 15px;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
}

.profile-text-link {
  text-decoration: none;
  color: #00ADD9;
}

.profile-icon {
  cursor: pointer;
}

@media (min-width: 840px) {
  .mdc-layout-grid__cell--span-8 {
    grid-column-end: span 6;
  }
}

@media (min-width: 1280px) {
  .mdc-layout-grid__cell--span-8 {
    grid-column-end: span 4;
    margin: 0 50px;
  }
}






.profile-section-header {
  background-color: #f3f4f7;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.profile-section-parent {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.helper-input-parent {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.helper-input {
  font-size: 12px;
  color: var(--color-text-primary);
}

.profile-input {
  width: 80%;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #00000044;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  padding-left: 10px;
  line-height: 35px;
  background-color: var(--color-surface);
  color: var(--color-text-secondary);
}

.profile-text-disabled {
  background-color: var(--color-surface);
  color: var(--color-text-secondary);
  border: 0 none;
}

.profile-text-disabled:hover {
  cursor: not-allowed;
}

.profile-input-disabled-parent {
  display: flex;
  align-items: center;
}

.help-icon {
  font-size: 20px;
  color: #00add9;
}

.pp-icon {
  font-size: 60px;
  color: black;
}

.pp-container {
  display: flex;
  align-items: center;
}

.pp-subcontainer {
  display: flex;
  flex-direction: column;
}

.pp-dialog-text {
  color: #1F7AA0;
  font-weight: bold;
}

@media (min-width: 901px) {
  .profile-parent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    //background-color: cornflowerblue;
    height: 100%;
    align-items: center;
    margin: 50px;
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 1279px) {
  #my-profile {
    margin-left: 50px !important;
  }
}

.profile-button {
  background-color: #00add9;
  border-radius: 20px;
  padding: 10px 50px;
  height: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 60%;
}

.profile-button:hover {
  background-color: #00a3cc;
  border-radius: 20px;
  padding: 10px 50px;
  height: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
}

.profile-section-parent {
  row-gap: 10px;
}

.profile {
  font-family: "Open Sans", sans-serif;
}

.helper-checkbox-parent input {
  accent-color: #009fc8;
}

.helper-checkbox-parent span {
  font-size: 14px;
}

.helper-checkbox-parent {
  margin-bottom: 10px;
}

.terminos {
  font-size: 12px;
  color: var(--color-text-primary);
}

.terminos a {
  color: #00add9;
  cursor: pointer;
  margin-bottom: 10px;
}

.LOPD-parent {
  margin-bottom: 0;
}

.checkbox-bottom-helper-parent {
  margin-bottom: 10px;
}

#profile-fail-load {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 20px;
}

#profile-fail-load h1 {
  font-size: 20px;
}

#profile-fail-load span {
  color: #00add9;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
</style>
